import { openDB, IDBPDatabase, DBSchema } from 'idb'
import type { IOfflineFormFile, IOfflineForm, FormStatus } from './form-types'
import { FileUploadValueType } from '../_types/components/commons/form'
import { arrayToMap } from '../_helpers/common.helpers'

/** The idb typings for FormDb */
interface FormDb extends DBSchema {
  // Store all local offline form submissions
  'forms': {
    key: string
    value: IOfflineForm
    indexes: {
      'by-status': FormStatus
      'by-type': string
    }
  }
  // Store all local offline form files
  'files': {
    key: string
    value: IOfflineFormFile
    indexes: {
      'by-form': string
    }
  }
}

const dbName = 'offline-forms'

/** access ( and update ) the db, to be called by all db access methods */
async function getDb (): Promise<IDBPDatabase<FormDb>> {
  return await openDB<FormDb>(dbName, 1, {
    upgrade (db) {
      const forms = db.createObjectStore('forms', {
        keyPath: 'key'
      })
      forms.createIndex('by-type', 'type')
      forms.createIndex('by-status', 'status')
      const files = db.createObjectStore('files', {
        keyPath: 'key'
      })
      files.createIndex('by-form', 'formKey')
    }
  })
}

/** Get a single submission record by key */
export async function getForm (key: string): Promise<IOfflineForm | undefined> {
  const db = await getDb()
  return await db.get('forms', key)
}

/** Save an Offline form, optionally including it's files */
export async function saveForm (form: IOfflineForm, files: IOfflineFormFile[] = []): Promise<void> {
  const db = await getDb()
  const tx = await db.transaction(['forms', 'files'], 'readwrite')

  const formToStore = { ...form } // Create a plain serializable copy of the form
  const filesToStore = files.map(f => ({ ...f })) // Create plain serializable copies of files

  await Promise.all([
    tx.objectStore('forms').put(formToStore),
    tx.objectStore('files').index('by-form').getAllKeys(),
    ...filesToStore.map(async (f) => await tx.objectStore('files').put(f)),
    tx.done
  ])
}

/** Delete a form, and it's related files */
export async function deleteForm (formKey: string, fileKeys: string[]): Promise<void> {
  const db = await getDb()
  const tx = db.transaction(['forms', 'files'], 'readwrite')
  await Promise.all([
    tx.objectStore('forms').delete(formKey),
    ...fileKeys.map(async (f) => await tx.objectStore('files').delete(f)),
    tx.done
  ])
}

/** Get all forms of a given type */
export async function getFormsByType (type: string): Promise<IOfflineForm[]> {
  const db = await getDb()
  return await db.getAllFromIndex('forms', 'by-type', type)
}

/** Get a file by key */
export async function getFile (key: string): Promise<IOfflineFormFile> {
  const db = await getDb()
  const file = await db.get('files', key)
  if (file === undefined) {
    throw new Error('file not found')
  }
  return file
}

/** Get all files by formKey */
export async function getFilesByFormKey (formKey: string): Promise<IOfflineFormFile[]> {
  const db = await getDb()
  const files = await db.getAllFromIndex('files', 'by-form', formKey)
  return files
}

/** Get forms of a given status (e.g. all pending forms for submission) */
export async function getAllByStatus (status: FormStatus): Promise<IOfflineForm[]> {
  const db = await getDb()
  return await db.getAllFromIndex('forms', 'by-status', status)
}

/**
 * delete files form IDB based on formkey
 * @param formKey string
 */
export async function deleteFilesByFormKey (formKey: string): Promise<void> {
  const db = await getDb()
  const tx = db.transaction('files', 'readwrite') // Start a transaction on the 'files' object store
  const store = tx.objectStore('files')

  // Use the delete method to remove a specific record based on formKey
  await store.delete(formKey)

  await new Promise<void>((resolve, reject) => {
    tx.oncomplete = () => {
      resolve()
    }
    tx.onerror = () => {
      reject(tx.error)
    }
  })
}
